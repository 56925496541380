import React from "react"
import SEO from "../components/seo"
import { FaArrowRight } from "react-icons/fa"

import "../scss/youradvisor.scss"
import "../scss/companies.scss"

import HeaderLogoImg from "../assets/img/compsol/advisors_1.svg"
import Icon1 from "../assets/img/yourAdvisors/Icon1.png"
import Icon2 from "../assets/img/yourAdvisors/Icon2.png"
import Icon3 from "../assets/img/yourAdvisors/Icon3.png"
import Icon4 from "../assets/img/yourAdvisors/Icon4.png"
import Icon5 from "../assets/img/yourAdvisors/Icon5.png"
import TickIcon from "../assets/img/yourAdvisors/tick.svg"
import GroupImg1 from "../assets/img/yourAdvisors/GroupImg1.png"
import GroupImg2 from "../assets/img/yourAdvisors/GroupImg2.png"
import Person1 from "../assets/img/yourAdvisors/person1.png"
import Person2 from "../assets/img/yourAdvisors/person2.png"
import Person3 from "../assets/img/yourAdvisors/person3.png"
import SingleForm from "../components/singleForm"

const YourAdvisorsPage = () => {
  return (
    <div>
      <SEO
        title="Youradvisors"
        keywords={[`youpal`, `youpal group`, `yourAdvisors`]}
      />
      <div className="youMain yourAdvisorsMain">
        <section className="container sectionHeader">
          <div className="col">
            <HeaderLogoImg className="headerLogo" />
            <div className="internalMainHeader bugP">
              <h2 className="internalNarrowCol">
              We Are Simplifying The Legal Process With Confidence
              </h2>
            </div>
            <p className="internalNarrowCol texting">
            Youradvisors brings law and technology together for improved accessibility and to help business growth.
            </p>
            <p className="internalNarrowCol texting">
            Our firm is built on a digital infrastructure.
            </p>
          </div>
        </section>

        <section className="container internalServices">
          <div className="col">
            <span className="internalNarrowCol internalSmallHeader">
              Services
            </span>
            <h3 className="internalNarrowCol">
            Youradvisors Will Keep Thinking Outside Of Your Case
            </h3>
            <p className="internalNarrowCol">
            Youradvisors offers services backed by AI-powered software solutions and Smart Contracts.
            </p>
            <p className="internalNarrowCol">
            We make every legal action and interaction comfortable and comprehensive for everyone.
            </p>
          </div>
        </section>

        <section className="container contactsSection">
          <div className="row">
            <div className="col serviceContainer">
              <div>
                <img src={Icon1} />
              </div>
              <h4>Artificial Intelligence</h4>
              <p>
              Using AI in the context of law assists us to get the most value out of any case
              </p>
            </div>
            <div className="col serviceContainer">
              <div className="imageSeparation">
                <img src={Icon2} />
              </div>
              <h4>Legal Consulting</h4>
              <p>
              Efficient management and the combined power of AI and consulting to fast track your process.
              </p>
            </div>
            <div className="col serviceContainer">
              <div className="imageSeparation">
                <img src={Icon3} />
              </div>
              <h4>Smart Contracts</h4>
              <p>
              Smart Contracts enable easy execution, control or documentation of legally-defined actions within the contract or agreement.
              </p>
            </div>
          </div>
        </section>

        <section className="container internalSection internalFeatures">
          <div className="row TwoCol">
            <div className="col">
              <img className="sideImg" src={GroupImg1} />
            </div>
            <div className="col">
              <div className="col sideContent">
                <div className="internalSmallSecondaryHeader text-left">
                  Features
                </div>
                <h3 className="text-left">
                Bringing the Value of Law to You
                </h3>
                <p className="internalFeaturesText">
                Our digital infrastructure helps us think outside the box for every case.
                </p>
                <div className="row">
                  <div className="col">
                    <div className="squareHolder">
                      <img src={Icon4} />
                    </div>
                    <h4>Fast Searching Process</h4>
                    <p>
                    Youradvisors is the fastest and the most outstanding place to seek for help when the legal borders are oppressing you.
                    </p>
                  </div>
                  <div className="col">
                    <div className="squareHolder">
                      <img src={Icon5} />
                    </div>
                    <h4>AI-integrated legal platform</h4>
                    <p>
                    The AI - integrated platform will assist you in finding the best solution for the success of your case.
                    </p>
                  </div>
                </div>
                <div className="buttonArea">
                  <a href="https://youradvisors.se" target="_blank">
                    <button type="button" className="btn btn-primary px-4">
                      <span>Find out more</span>
                      <FaArrowRight style={{ fill: "white" }} />
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container exploreSection">
          <div className="row">
            <div className="col">
              <div className="internalSmallSecondaryHeader">Lawyers</div>
              <h3>We are Your Advisors</h3>
              <p className="exploreSectionSubtitle">
              Our team of lawyers also understand AI technology and modern consulting. This rare skillset helps them adapt to standard procedures and help you.
              </p>
              <div className="row">
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Person1} />
                    </div>
                    <div className="col sideContent">
                      <h4>Dilan Samuelsson</h4>
                      <p>PARTNER</p>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Person2} />
                    </div>
                    <div className="col sideContent">
                      <h4>Camilla Leahlander</h4>
                      <p>PARTNER</p>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Person3} />
                    </div>
                    <div className="col sideContent">
                      <h4>Maria Lindholm</h4>
                      <p>LAWYER</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container internalSection internalList">
          <div className="row TwoCol">
            <div className="col">
              <div className="col sideContent">
                <h3>We will help you bring your business to the next level</h3>
                <p>
                Youradvisors aims to convert any legal barrier you face into a bridge for you and your progress. Our priority is creating a safe and friendly legal environment.
                </p>
                <div className="row collaborationList">
                  <div className="col-6">
                    <img className="pl-md-0" src={TickIcon} />
                    Timely procedures
                  </div>
                  <div className="col-6">
                    <img className="pl-md-0" src={TickIcon} />
                    AI-based software
                  </div>
                  <div className="col-6">
                    <img className="pl-md-0" src={TickIcon} />
                    Experienced Team
                  </div>
                  <div className="col-6">
                    <img className="pl-md-0" src={TickIcon} />
                    Automated process
                  </div>
                  <div className="col-6">
                    <img className="pl-md-0" src={TickIcon} />
                    Accessible and easy
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <img className="sideImg" src={GroupImg2} />
            </div>
          </div>
        </section>

        <section className="container">
          <div className="row contactBox">
            <SingleForm resourceUrl="https://ma.youpal.se/form/generate.js?id=23" selector="contact-us-mautic-company-form" />
          </div>
        </section>
      </div>
    </div>
  )
}

export default YourAdvisorsPage
